<template>
  <v-container id="Registration" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Register</div>

            <div class="subtitle-1 font-weight-light">
              Fill-in entries for registration.
            </div>
          </template>

          <v-form v-model="valid" ref="form">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Name"
                    required
                    :rules="nameRules"
                    v-model="form.name"
                    hint="Last Name, Given Name (ex: Doe, John or Santos, Juan)"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Primary Contact Number"
                    required
                    :rules="contactRules"
                    v-model="form.contact1"
                    hint="09XXXXXXXXX"
                    :counter="11"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Secondary Secondary Number"
                    hint="09XXXXXXXXX"
                    v-model="form.contact2"
                    :counter="11"
                  />
                </v-col>
                <!-- <v-col cols="12" md="4">
                  <v-autocomplete
                    :items="location"
                    hint="Select among the City/ Municipality for Verification"
                    outlined
                    label="Select among the City/ Municipality for Verification"
                  ></v-autocomplete>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Email Address"
                    required
                    :rules="emailRules"
                    v-model="form.email"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Password"
                    :type="show_pass ? 'text' : 'password'"
                    hint="Password"
                    required
                    :rules="passwordRules"
                    v-model="form.password"
                    :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show_pass = !show_pass"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Password Confirmation"
                    hint="Password Confirmation"
                    required
                    v-model="form.password_confirmation"
                    :rules="passwordRules"
                    :type="show_pass ? 'text' : 'password'"
                    :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show_pass = !show_pass"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-checkbox
                    v-model="checkbox"
                    :rules="agreeterms"
                  >
                  <div slot="label">
                    By clicking Register, you agree to our 
                    <v-btn text color="primary" to='/termsagreementpolicy'>Terms and Agreement Policy</v-btn>

                  </div>
                </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <vue-recaptcha
                    :sitekey="siteKey"
                    ref="recaptcha"
                    @verify="onVerify"
                  ></vue-recaptcha>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" md="2">
                  <v-btn to="/">Back</v-btn>
                </v-col>
                <v-col cols="4" md="2"> </v-col>
                <v-col cols="4" md="2" align-self="center">
                  <v-btn
                    color="success"
                    right
                    :disabled="!valid"
                    @click="submitForm"
                    :loading="loading"
                    >Register</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "RegistrationForm",
  data: () => ({
    siteKey: "6LfRCbwhAAAAAN2ERRMfNxIPYGtdHzlS0ZYrabox",
    show_pass: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 155 || "Name must be less than 155 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    contactRules: [
      (v) => !!v || "Contact is required",
      (v) => v.length == 11 || "Contact must be 11 Numbers",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 8 || "Password must be 8 or more characters",
    ],
    agreeterms:[
      (v)=>!!v || "Agree to terms is required",
      (v)=>true
    ],
    valid: false,
    form: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      contact1: "",
      contact2: "",
      notrobot: false,
    },
    loading: false,
    passwordValidation: false,
  }),
  components: {
    VueRecaptcha,
  },
  methods: {
    //submit form
    async submitForm() {
      this.loading = true;
      this.passwordValidation = await this.passwordMatch();

      if (this.passwordValidation && this.form.notrobot) {
        await this.$store.dispatch("registerUser", this.form);

        if (await this.notifyRegistration()) {
          this.$store.dispatch("clearRegistration");
          this.$router.push({ path: "/login" });
        }
      } else {
        this.$toast.error("Failed Registration");
      }
      this.loading = false;
    },

    //validate password
    passwordMatch() {
      if (this.form.password == this.form.password_confirmation) {
        return true;
      } else {
        this.$toast.error("Password do not match");
        return false;
      }
    },

    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },

    async notifyRegistration() {
      if (await this.$store.state.registration) {
        this.$toast.open({
          message: "Registration Success, verify on you Email",
          type: "success",
          dismissible: true,
          pauseOnHover: true,
        });
        return true;
      } else {
        this.$toast.error("Failed Registration");
        return false;
      }
    },

    onVerify(response) {
      if (response) {
        this.form.notrobot = true;
      } else {
        this.form.notrobot = false;
      }
    },
  },
};
</script>
